import { useLoaderData, useSearchParams } from '@remix-run/react';
import { type ClientLoaderFunctionArgs } from '@remix-run/react';
import { formatDistanceToNowStrict } from 'date-fns';
import pluralize from 'pluralize';
import React, { useCallback, useMemo, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import useSWR from 'swr';

import {
  type DtoInquiry,
  type DtoSummarizedInterview,
} from '@lp-lib/api-service-client/public';

import { CollectiveProviders } from '../components/Collective/Providers';
import { markdownComponents } from '../components/Collective/utils';
import { CopyButton } from '../components/common/CopyButton';
import { CopyLinkIcon } from '../components/icons/CopyLinkIcon';
import { LPLogo } from '../components/icons/LPLogo';
import { UserJoyCapture } from '../components/JoyCapture';
import { Loading } from '../components/Loading';
import { apiService } from '../services/api-service';
import { tokenWithRedirect } from '../utils/router';

export const clientLoader = async (action: ClientLoaderFunctionArgs) => {
  const id = action.params.id;
  if (!id) {
    throw new Error('expected inquiry id');
  }

  const resp = await tokenWithRedirect(
    () => apiService.collective.getInquiry(id),
    action.request.url
  );

  return { data: resp.data };
};

function Inquiry() {
  return (
    <CollectiveProviders>
      <InquiryOverview />
    </CollectiveProviders>
  );
}

export const Component = Inquiry;

function Layout(props: { children: React.ReactNode }): JSX.Element {
  return (
    <div className='w-full h-full text-white flex flex-col min-h-0 bg-layer-001 overflow-auto'>
      <header className='w-full items-center pl-2.5 pt-2.5 pr-5 justify-between'>
        <LPLogo />
      </header>
      {props.children}
    </div>
  );
}

function InquiryOverview(): JSX.Element {
  const loaded = useLoaderData<typeof clientLoader>();
  const inquiry = loaded.data?.inquiry;

  const interviewUrl = `${window.location.origin}/inquiries/${inquiry.id}/interview`;

  const tabs = [
    {
      id: 'summary',
      label: 'Summary',
      component: <Summary inquiry={inquiry} />,
    },
    {
      id: 'responses',
      label: <ResponsesTabLabel inquiryId={inquiry.id} />,
      component: <Responses inquiryId={inquiry.id} />,
    },
    {
      id: 'inquiry',
      label: 'Inquiry',
      component: <InquiryQuestions inquiry={inquiry} />,
    },
  ];

  return (
    <Layout>
      <main className='w-full flex-1 flex justify-center'>
        <div className='w-full flex-1 flex flex-col lg:max-w-200'>
          <div className='px-5'>
            <div className='flex items-center justify-between'>
              <div className='text-3xl md:text-3.5xl font-bold'>
                Inquiry Overview
              </div>
              <CopyButton copiedText={interviewUrl}>
                <CopyLinkIcon className='w-6 h-6 fill-current' />
              </CopyButton>
            </div>
            <div className='text-sm text-icon-gray'>
              {new Date(inquiry.createdAt).toLocaleDateString()}
            </div>
            <div className='my-5 text-base text-secondary line-clamp-2'>
              {inquiry.query}
            </div>
          </div>

          <TabGroup tabs={tabs} />
        </div>
      </main>
    </Layout>
  );
}

function TabGroup(props: {
  tabs: {
    id: string;
    label: string | React.ReactNode;
    component: React.ReactNode;
  }[];
}): JSX.Element {
  const [searchParams, setSearchParams] = useSearchParams();
  const activeTabId = searchParams.get('tab');
  const activeTabIndex = useMemo(() => {
    const index = props.tabs.findIndex((tab) => tab.id === activeTabId);
    return index === -1 ? 0 : index;
  }, [activeTabId, props.tabs]);

  const onTabClick = useCallback(
    (id: string) => {
      setSearchParams({ tab: id });
    },
    [setSearchParams]
  );

  const body = props.tabs[activeTabIndex].component;

  const tabs = useMemo(() => {
    return props.tabs.map((tab, index) => {
      const isActive = index === activeTabIndex;
      const className = isActive
        ? 'border-b border-white h-10 font-bold'
        : 'h-10 text-icon-gray font-bold';
      return (
        <div
          key={index}
          className={`cursor-pointer hover:text-white transition-colors ${className}`}
          onClick={() => onTabClick(tab.id)}
        >
          {tab.label}
        </div>
      );
    });
  }, [activeTabIndex, onTabClick, props.tabs]);

  return (
    <>
      <div className='pt-4 px-5 flex items-center gap-6'>{tabs}</div>
      <div className='flex-1 bg-layer-002 py-6'>{body}</div>
    </>
  );
}

async function loadResponses(
  inquiryId: string
): Promise<DtoSummarizedInterview[]> {
  const resp = await apiService.collective.getInquiryResponses(inquiryId);
  return resp.data.interviews ?? [];
}

function useInquiryResponses(inquiryId: string) {
  return useSWR<DtoSummarizedInterview[]>(
    `/inquiries/${inquiryId}/responses`,
    () => loadResponses(inquiryId),
    {
      shouldRetryOnError: false,
    }
  );
}

function ResponsesTabLabel(props: { inquiryId: string }): JSX.Element {
  const { data } = useInquiryResponses(props.inquiryId);
  const label =
    data && data.length > 0 ? `Responses (${data.length})` : 'Responses';
  return <>{label}</>;
}

function Responses(props: { inquiryId: string }): JSX.Element {
  const { data, isLoading, error } = useInquiryResponses(props.inquiryId);

  if (error) {
    return (
      <div className='w-full h-full flex items-center justify-center text-center'>
        Hmm, something went wrong. Please try again later.
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className='w-full h-full flex items-center justify-center'>
        <Loading text='' />
      </div>
    );
  }

  const responses = data ?? [];
  if (responses.length === 0) {
    return (
      <div className='w-full h-full flex flex-col items-center justify-center text-center'>
        <p>No responses yet</p>
        <div className='text-secondary text-sm'>
          Share your inquiry with your colleagues.
        </div>
      </div>
    );
  }

  return (
    <div className='px-2 md:px-5 space-y-12'>
      {(data ?? []).map((interview) => (
        <div
          key={interview.id}
          className='space-y-2 bg-dark-gray p-4 rounded-xl shadow-lg'
        >
          <div className='flex items-center gap-2'>
            <UserJoyCapture
              uid={interview.uid}
              styles={{
                size: 'w-10 h-10',
                text: 'text-base',
              }}
              noAnimate
            />
            <div className='flex flex-col'>
              <div className='text-sm text-white font-bold'>
                {interview.userName}
              </div>
              <div className='text-xs text-secondary'>
                {formatDistanceToNowStrict(new Date(interview.updatedAt), {
                  addSuffix: true,
                })}
              </div>
            </div>
          </div>
          <div className='text-sm'>
            <div className='overflow-auto scrollbar text-sms whitespace-pre-wrap'>
              <ReactMarkdown components={markdownComponents}>
                {interview.summary}
              </ReactMarkdown>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

function InquiryQuestions(props: { inquiry: DtoInquiry }): JSX.Element {
  const { questions, query } = props.inquiry;

  return (
    <div className='space-y-10'>
      <div className='px-5'>
        <div className='pb-2 font-bold'>Prompt</div>
        <div className='text-icon-gray'>{query}</div>
      </div>

      <div>
        <div className='px-5 pb-2 font-bold'>Questions</div>
        <div className='px-2 md:px-5 space-y-4'>
          {(questions ?? []).map((question, index) => (
            <div
              key={question.id}
              className='bg-dark-gray p-4 rounded-xl shadow-lg'
            >
              <div className='text-sm'>
                {index + 1}. {question.text}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

async function loadInquiry(inquiryId: string): Promise<DtoInquiry> {
  const resp = await apiService.collective.getInquiry(inquiryId);
  return resp.data.inquiry;
}

function useInquiry(inquiry: DtoInquiry) {
  return useSWR<DtoInquiry>(
    `/inquiries/${inquiry.id}`,
    () => loadInquiry(inquiry.id),
    {
      shouldRetryOnError: false,
      fallback: inquiry,
    }
  );
}

function Summary(props: { inquiry: DtoInquiry }): JSX.Element {
  const inquiry = useInquiry(props.inquiry);
  const responses = useInquiryResponses(props.inquiry.id);
  const [isSummarizing, setIsSummarizing] = useState(false);

  const handleSummarize = async () => {
    setIsSummarizing(true);
    try {
      const resp = await apiService.collective.summarizeInquiry(
        props.inquiry.id
      );
      await inquiry.mutate(resp.data.inquiry);
    } finally {
      setIsSummarizing(false);
    }
  };

  if (inquiry.isLoading || responses.isLoading) {
    return (
      <div className='w-full h-full flex items-center justify-center'>
        <Loading text='' />
      </div>
    );
  }

  if (inquiry.error || responses.error || !inquiry.data) {
    return (
      <div className='w-full h-full flex items-center justify-center text-center'>
        Hmm, something went wrong. Please try again later.
      </div>
    );
  }

  const responsesData = responses.data ?? [];
  const numResponses = responsesData.length;
  if (numResponses === 0) {
    return (
      <div className='px-8 w-full h-full flex flex-col items-center justify-center text-center'>
        <p>No responses yet</p>
        <div className='text-secondary text-sm'>
          Once you have responses, you can summarize them here.
        </div>
      </div>
    );
  }

  const { generatedSummary, summaryGeneratedAt } = inquiry.data;
  const mostRecentResponse = responsesData.reduce(
    (acc, curr) => (curr.updatedAt > acc.updatedAt ? curr : acc),
    responsesData[0]
  );
  const resummarize =
    summaryGeneratedAt && mostRecentResponse.updatedAt <= summaryGeneratedAt;
  const summarizeButton = (
    <button
      type='button'
      className={`
        btn w-full h-16 md:max-w-75
        flex items-center justify-center
        bg-[#FF0935] hover:bg-[#FF2D53] transition-colors
        font-bold text-center tracking-wide
      `}
      onClick={handleSummarize}
      disabled={isSummarizing}
    >
      {isSummarizing ? (
        <Loading text='' />
      ) : numResponses === 0 ? (
        'Summarize'
      ) : resummarize ? (
        'Re-summarize'
      ) : (
        `Summarize ${numResponses} ${pluralize('Response', numResponses)}`
      )}
    </button>
  );

  if (generatedSummary) {
    return (
      <div className='w-full px-5'>
        <div className='overflow-auto scrollbar text-sms whitespace-pre-wrap'>
          <ReactMarkdown components={markdownComponents}>
            {generatedSummary}
          </ReactMarkdown>
        </div>
        {summaryGeneratedAt && (
          <div className='flex items-center justify-end pt-3'>
            <div className='text-xs text-secondary'>
              Generated{' '}
              {formatDistanceToNowStrict(new Date(summaryGeneratedAt), {
                addSuffix: true,
              })}
            </div>
          </div>
        )}
        <div className='pt-8 w-full flex justify-center'>{summarizeButton}</div>
      </div>
    );
  } else {
    return (
      <div className='w-full h-full flex flex-col items-center justify-center text-center px-5'>
        <p>Generate a summary</p>
        <div className='text-secondary text-sm px-8'>
          Summarize the responses to your inquiry to see the most common themes.
        </div>
        <div className='pt-4 w-full flex justify-center'>{summarizeButton}</div>
      </div>
    );
  }
}
